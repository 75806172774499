const categories = {
  Outers: ["Jackets", "Down/Puffers", "Coats", "Cardigans", "Vests", "Others"],
  Tops: ["T-Shirts", "Shirts", "Knitwear", "Sweats", "Others"],
  Bottoms: ["Jeans", "Shorts", "Skirts", "Others"],
  Dresses: ["Others"],
  Shoes: ["Others"],
  Bags: ["Others"],
  Hats: ["Others"],
  Accessories: ["Others"],
  Lifestyles: ["Others"],
  Women: ["Others"],
};

export default categories;
